// Migrated
<template>
  <nuxt-link
    class="charter-start-box flex flex-col relative bg-white text-black drop-shadow-below-blur hover-scale-img"
    :to="url"
  >
    <div class="max-h-px-400">
      <div class="aspect-4/4 relative">
        <ResponsiveImage
          class="size-full top-0 max-h-px-400"
          position="absolute"
          :image="image"
          alt="Solresor"
          cover
        />
      </div>
    </div>
    <div
      v-if="price"
      class="h-[70px] flex items-center px-6 charter-start-box__text-with-price"
    >
      <div class="h5 mb-0">
        {{ text }}
      </div>
      <div class="h5 mb-0 ml-auto text-right">
        <span class="font-normal">
          {{ $t('shortFromPrice') }}
        </span>
        <span class="text-nowrap">
          {{ $n(price) }}
        </span>
      </div>
    </div>
    <div
      v-else
      class="h-[70px] flex items-center justify-center charter-start-box__text-without-price"
    >
      <div class="h5 mb-0">
        {{ text }}
      </div>
    </div>
    <div class="absolute top-0 left-0 mt-6 z-[1]">
      <slot name="top-left" />
    </div>
  </nuxt-link>
</template>

<script>
export default defineNuxtComponent({
  name: 'Box',

  props: {
    url: {
      type: String,
      required: true,
    },

    text: {
      type: String,
      default: null,
    },

    price: {
      type: Number,
      default: null,
    },

    image: {
      type: String,
      default: null,
    },
  },
})
</script>

<style lang="scss" scoped>
.charter-start-box {
  .charter-start-box__text-with-price {
    .h5 {
      font-size: 1.3rem;
    }
  }

  .charter-start-box__text-without-price {
    .h5 {
      font-size: 1.2rem;
    }
  }
}
</style>
